import { $themeBreakpoints } from '@themeConfig'
import { $themeConfig } from '@themeConfig'
import store from '..'

export default {
  namespaced: true,
  state: {
    isLoading: false,
    isLoadingWebsite: true,
    windowWidth: 0,
    domainHost:  $themeConfig.app.domainPath,
    shallShowOverlay: false,
    lang: 1
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
    isLoading(state) {
      return state.isLoading
    },
    isLoadingWebsite(state) {
      return state.isLoadingWebsite
    },
    domainHost({domainHost}) {
      return domainHost
    },
    lang({lang}) {
      return lang
    }
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    Set_Lang(state, lang) {
      state.lang = lang
    }
  },
  actions: {
    setLoading({state}, is) {
        state.isLoading = is
    },
    setLoadingWebsite({state}, is) {
      state.isLoadingWebsite = is
    },
    pageChanged(context, pageInfo) {
      const firstPageItem = pageInfo.pageLength * (pageInfo.currentPage - 1)
      return pageInfo.items.slice(firstPageItem, firstPageItem + pageInfo.pageLength)
    },
    filterItems(ctx, payload) {
      const {items, key, search} = payload
      return store.getters[items].filter((item) => {
          return item[key].indexOf(search) != -1
      })
    },
    setLang({commit}, lang) {
      commit('Set_Lang', lang)
    }
  },
}
