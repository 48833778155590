import axiosIns from "@/libs/axios";

export default {
    state: {
        categoriesShopsCount: [],
        categoriesCount:0,
        activitiesCount:0,
        monthlyActivitiesCount:0,
        servicesCount:0
    },
    getters: {
        categoriesShopsCount({ categoriesShopsCount }) {
            return categoriesShopsCount;
        }
    },
    mutations: {
        GET_HOME_STATS(state, props) {
            state.categoriesShopsCount = props.categoriesShopsCount;
            state.categoriesCount = props.categoriesCount;
            state.activitiesCount = props.activitiesCount;
            state.monthlyActivitiesCount = props.monthlyActivitiesCount;
            state.servicesCount = props.servicesCount;
        }
    },
    actions: {
        async getHomeStats({ commit }) {
            const { data } = await axiosIns.get('Home/GetHomeStats');
            commit("GET_HOME_STATS", data)
        },
    }


}