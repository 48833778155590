import {Admin, Editor} from "../index"
export default [
  {
    path: '/admin/user-accounts',
    components: {
      default: () => import('@/views/setting/user-accounts/index.vue'),
      'bread-actions': () => import('@/views/setting/user-accounts/button-index.vue')
    },
    children: [
      {
        path: '',
        name: 'user accounts',
        components: {
          default: () => import('@/views/setting/user-accounts/pages/main.vue'),
          'actions': () => import('@/views/setting/user-accounts/components/button-create-user-account.vue')
        },
        props: true,
        meta: () => ({
          pageTitle: 'حسابات المستخدمين',
          roles: [Admin],
          breadcrumb: [
            {
              text: 'حسابات المستخدمين',
              icon: 'user-square',
              active: true,
            },
          ],
        }),
      },
      {
        path: 'details/:id',
        name: 'user accounts details',
        component: () => import('@/views/setting/user-accounts/pages/details.vue'),
        props: true,
        meta: () => ({
          roles: [Admin],
          pageTitle: 'حسابات المستخدمين',
          breadcrumb: [
            {
              text: 'حسابات المستخدمين',
              icon: 'user-square',
              to: '/admin/user-accounts',
              active: false,
            },
            {
              text: 'تفاصيل الحساب',
              active: true,
            }
          ],
        }),
      }
    ],
  },
  {
    path: '/admin/categories/:lang?',
    name: 'categories',
    components: {
      default: () => import('@/views/setting/categories'),
      'bread-actions': () => import('@/views/setting/categories/components/button-create-categorie.vue')
    },
    props: {
      default: true
    },
    meta: () => ({
      pageTitle: 'التصنيفات',
      roles: [Admin, Editor],
      breadcrumb: [
        {
          text: 'التصنيفات',
          icon: 'vertical-distribution-center',
          active: true,
        },
      ],
    }),
  },
  {
    path: '/admin/service-setting/:lang?',
    name: 'serviceSetting',
    components: {
      default: () => import('@/views/setting/page-setting/service-setting'),
      'bread-actions': () => import('@/views/setting/page-setting/service-setting/components/button-create-service-images.vue')
    },
    props: {
      default: true
    },
    meta: () => ({
      pageTitle: 'إعداد الخدمات',
      roles: [Admin],
      breadcrumb: [
        {
          text: 'إعداد الخدمات',
          icon: 'vertical-distribution-center',
          active: true,
        },
      ],
    }),
  },
  {
    path: '/admin/product-setting/:lang?',
    name: 'productSetting',
    components: {
      default: () => import('@/views/setting/page-setting/product-setting'),
    },
    props: {
      default: true
    },
    meta: () => ({
      pageTitle: 'إعداد المنتج',
      roles: [Admin],
      breadcrumb: [
        {
          text: 'إعداد المنتج',
          icon: 'vertical-distribution-center',
          active: true,
        },
      ],
    }),
  },
  {
    path: '/admin/company-setting',
    name: 'company setting',
    components: {
      default: () => import('@/views/setting/company-setting'),
    },
    meta: () => ({
      pageTitle: 'إعدادات الشركة',
      roles: [Admin],
      breadcrumb: [
        {
          text: 'إعدادات الشركة',
          icon: 'setting',
          active: true,
        },
      ],
    }),
  },
  {
    path: '/admin/company-info/:lang?',
    name: 'company info',
    components: {
      default: () => import('@/views/setting/company-info'),
    },
    props: {
      default: true
    },
    meta: () => ({
      pageTitle: 'ملف الشركة',
      roles: [Admin],
      breadcrumb: [
        {
          text: 'ملف الشركة',
          icon: 'files-landscapes',
          active: true,
        },
      ],
    }),
  },
]