import axiosIns from "@/libs/axios"
import store from "@/store"
import { BToast } from "bootstrap-vue";

export default {
    state: {
      isCreateProductSettingieModal: false,
      productsPageDto: {
        id: 3,
        pageType: 2,
        title: "",
        description: ""
      }
    },
    getters: {
      isCreateProductSettingieModal({isCreateProductSettingieModal}) {
        return isCreateProductSettingieModal
      },
      productsPageDto({productsPageDto}) {
        return productsPageDto
      }
    },
    mutations: {
      Get_Products_Setting_Page(state, data) {
        Object.assign(state.productsPageDto, data)
      }
    },
    actions: {
      setIsCreateProductSettingieModal({state}, is) {
        state.isCreateProductSettingieModal = is
        return is
      },
      async getProductsSettingPage({commit}, lang) {
        const {data} = await axiosIns.get(`PageSettings/GetPageSettings?language=${lang}&pageType=1`)
        commit('Get_Products_Setting_Page', data)
      },

      setProductsSettingPage({commit}, dto) {
        dto.language = store.state.app.lang
        const bToast = new BToast();
        axiosIns.post(`PageSettings/SetPageSettings`, dto)
        .then(({ data }) => {
          commit('Get_Products_Setting_Page', data)
          bToast.$bvToast.toast(`تم تعديل إعدادات المنتج`, {
            title: "تم التعديل",
            variant: "success",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        })
        .catch(() => {
          bToast.$bvToast.toast("فشل تعديل إعدادات المنتج", {
            title: "فشل التعديل",
            variant: "danger",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        });
      },
    }
  }