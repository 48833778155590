import axiosIns from "@/libs/axios";
import { BToast } from "bootstrap-vue";

export default {
  state: {
    settings: {
      id: 0,
      videoUrl: "",
      showPrices: false,
      showCustomers: false,
    },
    currency: {
      id: 0,
      name: "",
      value: 0,
      certified: true,
      showPrices: true,
    },
  },
  getters: {
    settings({ settings }) {
      return settings;
    },
    currency({ currency }) {
      return currency;
    },
  },
  mutations: {
    Fetch_Settings(state, payload) {
      Object.assign(state.settings, payload);
    },
    Update_Video_Url(state, payload) {
      state.settings.videoUrl = payload.videoUrl;
    },
    Update_Show_Prices(state, payload) {
      state.settings.showPrices = payload.showPrices;
    },
    Update_Show_Customers(state, payload) {
      state.settings.showCustomers = payload.showCustomers;
    },
    Get_Currency(state, payload) {
      state.currency = payload;
    },
    Edit_Currency_Price(state, payload) {
      state.currency = payload;
    },
  },
  actions: {
    async fetchSettings({ commit }) {
      const { data } = await axiosIns.get("Settings/GetSettings");
      commit("Fetch_Settings", data);
    },
    async getCurrency({ commit }) {
      const { data } = await axiosIns.get("Product/GetCurrency");
      commit("Get_Currency", data);
    },

    updateVideoUrl({ commit }, payload) {
      const bToast = new BToast();
      axiosIns
        .put("Settings/SetVideoUrl", payload)
        .then(({ data }) => {
          commit("Update_Video_Url", data);
          bToast.$bvToast.toast(`تم تعديل الفيديو`, {
            title: "تم التعديل",
            variant: "success",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        })
        .catch(() => {
          bToast.$bvToast.toast("فشل تعديل الفيديو", {
            title: "فشل التعديل",
            variant: "danger",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        });
    },

    updateShowPrices({ commit }, payload) {
      const bToast = new BToast();
      axiosIns
        .put("Settings/SetShowPrices", payload)
        .then(({ data }) => {
          commit("Update_Show_Prices", data);
          bToast.$bvToast.toast(`تم تعديل حالة الأسعار`, {
            title: "تم التعديل",
            variant: "success",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        })
        .catch(() => {
          bToast.$bvToast.toast("فشل تعديل حالة الأسعار", {
            title: "فشل التعديل",
            variant: "danger",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        });
    },

    updateShowCustomers({ commit }, payload) {
      const bToast = new BToast();
      axiosIns.put("Settings/SetShowCustomers", payload)
      .then(({ data }) => {
        commit("Update_Show_Customers", data);
          bToast.$bvToast.toast(`تم تعديل حالة الزبائن`, {
            title: "تم التعديل",
            variant: "success",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        })
        .catch(() => {
          bToast.$bvToast.toast("فشل تعديل حالة الزبائن", {
            title: "فشل التعديل",
            variant: "danger",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        });
    },

    editCurrencyPrice({ commit }, payload) {
      payload.value = +payload.value;
      const bToast = new BToast();
      axiosIns.post("Product/EditCurrency", payload)
      .then(({ data }) => {
        commit("Edit_Currency_Price", data);
          bToast.$bvToast.toast(`تم تعديل سعر الصرف`, {
            title: "تم التعديل",
            variant: "success",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        })
        .catch(() => {
          bToast.$bvToast.toast("فشل تعديل سعر الصرف", {
            title: "فشل التعديل",
            variant: "danger",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        });
    },
  },
};
