import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import { canNavigate } from "@/libs/acl/routeProtection";
import { isUserLoggedIn } from "@/auth/utils";

export const All = "All";
export const Admin = "Admin";
export const Editor = "Editor";
export const Support = "Support";
// Routes
import mainSections from "./main-sections";
import setting from "./setting";
import website from "./website";

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      component: () => import("@/views/website"),
      meta: () => ({
        roles: [All],
        layout: "full",
      }),
      children: website,
    },
    ...mainSections,
    ...setting,
    {
      path: "/admin/home",
      name: "home",
      component: () => import("@/views/Home.vue"),
      meta: () => ({
        roles: [Admin],
        pageTitle: "الرئيسية",
        breadcrumb: [
          {
            text: "الرئيسية",
            icon: "estate",
            active: true,
          },
        ],
      }),
    },
    {
      path: "/admin",
      redirect: "/admin/home",
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: () => ({
        pageTitle: "تسجيل الدخول",
        roles: [All],
        layout: "full",
      }),
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: () => ({
        pageTitle: "الصفحة غير موجودة",
        roles: [All],
        layout: "full",
      }),
    },
    {
        path: "/access-denied",
        name: "access-denied",
        component: () => import("@/views/error/NotAuthorized.vue"),
        meta: () => ({
            pageTitle: "ممنوع الوصول",
            layout: "full",
            roles: [All]
        })
    },
    {
      path: '',
      redirect: '/'
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});
router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn();
  document.title = !to.meta(to).breadcrumb
    ? !to.meta(to).pageTitle
      ? to.meta(to).title
      : to.meta(to).pageTitle
    : to.meta(to).breadcrumb[0].text;
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next("/login");

    // If logged in => not authorized
    return next("/access-denied");
  }
  // Redirect if logged in
  // if (to.meta(to).redirectIfLoggedIn && isLoggedIn) {
  //   const userData = getUserData();
  //   next(getHomeRouteForLoggedInUser(userData ? userData.role : null));
  // }

  return next();
});
export default router;
