import Vue from 'vue';
import store from '@/store';
export const EventBus = new Vue();

export function handleLangChange(action) {
    EventBus.$on('onLangChange', (id)=>{ 
        store.dispatch("app/setLoadingWebsite", true);
        action(id);      
    });
    
}
export function handleChangeUserInfo(action) {
    EventBus.$on('onChangeUserInfo', ()=>{ 
        action();      
    });
    
}