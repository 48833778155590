import axiosIns from "@/libs/axios"
import { BToast } from "bootstrap-vue";

export default {
  state: {
    isCreateInfoModal: false,
    isCreateImagesModal: false,
    companyDto: {
      id: 1,
      aboutType: 1,
      description: '',
      address: '',
      mobileNumber: '',
      phoneNumber: '',
      facebookLink: '',
      email: '',
      fax: '',
      latitude: '',
      longitude: '',
    },
    agentDto: {
      id: 2,
      aboutType: 2,
      name: '',
      mobileNumber: '',
      phoneNumber: '',
      facebookLink: '',
      email: '',
      imageDto: {
        id: 0,
        path: '',
        base64: ''
      }
    }
  },
  getters: {
    isCreateInfoModal({isCreateInfoModal}) {
      return isCreateInfoModal
    },
    isCreateImagesModal({isCreateImagesModal}) {
      return isCreateImagesModal
    },
    companyDto({companyDto}) {
      return companyDto
    },
    agentDto({agentDto}) {
      return agentDto
    }
  },
  mutations: {
    Get_Contact_Info(state, payload) {
      Object.assign(state.companyDto, payload.contactInfoList[0])
      Object.assign(state.agentDto, payload.contactInfoList[1])
    }
  },
  actions: {
    setIsCreateInfoModal({state}, is) {
      state.isCreateInfoModal = is
      return is
    },
    setIsCreateImagesModal({state}, is) {
      state.isCreateImagesModal = is
      return is
    },
    async getContactInfo({commit}) {
      const {data} = await axiosIns.get('ContactInfo/GetContactInfo')
      commit('Get_Contact_Info', data)
    },
    setContactInfo(ctx, dto) {
      const bToast = new BToast();
        axiosIns.post('ContactInfo/SetContactInfo', dto)
        .then(() => {
          bToast.$bvToast.toast(`تم تعديل البيانات بنجاح`, {
            title: "تم التعديل",
            variant: "success",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        })
        .catch(() => {
          bToast.$bvToast.toast("فشل تعديل البيانات", {
            title: "فشل التعديل",
            variant: "danger",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        });
    }
  }
}