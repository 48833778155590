import { EventBus } from "./event-bus";

export default class globalEvent {
  static changeLang() {
    EventBus.$emit("onLangChange");
  }
  static changeUserInfo() {
    EventBus.$emit("onChangeUserInfo");
  }
}
