import axiosIns from "@/libs/axios"
import store from "@/store"
import { BToast } from "bootstrap-vue";

export default {
  state: {
    isAboutModal: false,
    isGoalsModal: false,
    isValuesModal: false,
    
    valuesDash: [],
    goalsDash: [],
    aboutusDash: {
      id: 0,
      description: null,
      order: 0,
      aboutType: 0,
      language: 0,
      linkId: null,
      imageDto: {
        id: 0,
        path: '',
        base64: ''
      }
    }
  },
  getters: {
    isAboutModal({isAboutModal}) {
      return isAboutModal
    },
    isGoalsModal({isGoalsModal}) {
      return isGoalsModal
    },
    isValuesModal({isValuesModal}) {
      return isValuesModal
    },
    aboutusDash({aboutusDash}) {
      return aboutusDash
    },
    valuesDash({valuesDash}) {
      return valuesDash
    },
    goalsDash({goalsDash}) {
      return goalsDash
    },
  },
  mutations: {
    Get_About_Page(state, payload) {
      state.aboutusDash = payload.aboutDto
      state.goalsDash = payload.goals
      state.valuesDash = payload.values
    },
    Update_About(state, payload) {
      if (payload.aboutType == 1) {
        Object.assign(
          state.aboutusDash,
          payload
        )
      } else if (payload.aboutType == 2) {
        Object.assign(
          state.goalsDash.find(item => item.id == payload.id),
          payload
        )
      } else if (payload.aboutType == 3) {
        Object.assign(
          state.valuesDash.find(item => item.id == payload.id),
          payload
        )
      }
    },
  },
  actions: {
    setIsAboutModal({state}, is) {
      state.isAboutModal = is
      return is
    },
    setIsGoalsModal({state}, is) {
      state.isGoalsModal = is
      return is
    },
    setIsValuesModal({state}, is) {
      state.isValuesModal = is
      return is
    },
    async getAboutPage({commit}, lang) {
      const {data} = await axiosIns.get('Website/GetAboutUs?language=' + (!lang ? (store.state.appConfig.layout.isRTL ? 1 : 2) : lang))
      commit('Get_About_Page', data)
    },

    async updateAbout({commit}, payload) {
      payload.language = store.state.app.lang
      const bToast = new BToast();
      axiosIns.post('About/UpdateAbout', payload)
      .then(({ data }) => {
          commit('Update_About', data)
          bToast.$bvToast.toast(`تم التعديل المعلومات بنجاح`, {
            title: "تم التعديل",
            variant: "success",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        })
        .catch(() => {
          bToast.$bvToast.toast("فشل تعديل المعلومات", {
            title: "فشل التعديل",
            variant: "danger",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        });
    }
  }
}