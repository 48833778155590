import axiosIns from "@/libs/axios";
import store from "..";
import { BToast } from "bootstrap-vue";

export default {
  state: {
    navActive: false,
    valuesWebsite: [],
    goalsWebsite: [],
    websiteEmployeeList: [],
    websiteActivitiesList: [],
    filterdDataListProductWeb: [],
    DataFirstPageProductWeb: 1,
    DataPageLengthProductWeb: 6,
    aboutusWebsite: {
      id: 0,
      description: null,
      order: 0,
      aboutType: 0,
      language: 0,
      linkId: null,
      imageDto: {
        id: 0,
        path: "",
        base64: "",
      },
    },
    companyWebsiteDto: {
      id: 1,
      aboutType: 1,
      description: "",
      address: "",
      mobileNumber: "",
      phoneNumber: "",
      facebookLink: "",
      email: "",
      fax: "",
      latitude: "",
      longitude: "",
    },
    agentWebsiteDto: {
      id: 2,
      aboutType: 2,
      name: "",
      mobileNumber: "",
      phoneNumber: "",
      facebookLink: "",
      email: "",
      imageDto: {
        id: 0,
        path: "",
        base64: "",
      },
    },
    services: [],
    stages: [],
    stagesTitle: {},
    videoUrl: "",
    products: [],
    filterdProducts: [],
    productsMain: [],
    productsInfo: {
      id: 0,
      pageType: 0,
      title: "",
      description: "",
      imageDto: {
        id: 0,
        path: null,
        base64: null,
      },
      stages: [],
    },

    productDetailsForWebsite: {
      id: 0,
      name: "",
      description: "",
      imagePath: "",
      language: 0,
      mainCategory: "",
      subCategory: "",
      price:0,
    },
    productsListOther: [],
    filterDto: {
      main: null,
      sup: null,
      search: "",
    },
    setContactDto: {
      id: 0,
      name: "",
      email: "",
      title: "",
      description: "",
      response: "",
    },
    websiteMainCategoriesList: [],
    websiteSubCategoriesList: [],
    activeProductWeb:[],
    customerList:[]
  },
  getters: {
    customerList({customerList}) {
      return customerList
    },
    DataFirstPageProductWeb({DataFirstPageProductWeb}){
      return DataFirstPageProductWeb
    },
    activeProductWeb({activeProductWeb}){
      return activeProductWeb
    },
    DataPageLengthProductWeb({DataPageLengthProductWeb}){
      return DataPageLengthProductWeb
    },
    websiteSubCategoriesList({ websiteSubCategoriesList }) {
      return websiteSubCategoriesList;
    },
    filterDto({ filterDto }) {
      return filterDto;
    },
    websiteMainCategoriesList({ websiteMainCategoriesList }) {
      return websiteMainCategoriesList;
    },
    productsListOther({ productsListOther }) {
      return productsListOther;
    },
    productDetailsForWebsite({ productDetailsForWebsite }) {
      return productDetailsForWebsite;
    },
    setContactDto({ setContactDto }) {
      return setContactDto;
    },
    navActive({ navActive }) {
      return navActive;
    },
    aboutusWebsite({ aboutusWebsite }) {
      return aboutusWebsite;
    },
    websiteEmployeeList({ websiteEmployeeList }) {
      return websiteEmployeeList;
    },
    websiteActivitiesList({ websiteActivitiesList }) {
      return websiteActivitiesList;
    },
    companyWebsiteDto({ companyWebsiteDto }) {
      return companyWebsiteDto;
    },
    agentWebsiteDto({ agentWebsiteDto }) {
      return agentWebsiteDto;
    },
    valuesWebsite({ valuesWebsite }) {
      return valuesWebsite;
    },
    goalsWebsite({ goalsWebsite }) {
      return goalsWebsite;
    },
    services({ services }) {
      return services;
    },
    stages({ stages }) {
      return stages;
    },
    stagesTitle({ stagesTitle }) {
      return stagesTitle;
    },
    videoUrl({ videoUrl }) {
      return videoUrl;
    },
    products({ products }) {
      return products;
    },
    productsInfo({ productsInfo }) {
      return productsInfo;
    },
    filterdProducts({ filterdProducts }) {
      return filterdProducts;
    }
  },
  mutations: {
    Set_Is_Nav_Active(state, is) {
      state.navActive = is;
    },
    Get_Home_Page_For_Website(state, payload) {
      state.aboutusWebsite = payload.aboutUs;
      state.stages = payload.stages;
      state.videoUrl = payload.videoUrl;
      state.services = payload.services;
      state.products = payload.products;
      state.stagesTitle = payload.stagesTitle;
      state.websiteActivitiesList = payload.activities;
      state.customerList = payload.customers
    },
    Get_About_Page_For_Website(state, payload) {
      state.aboutusWebsite = payload.aboutDto;
      state.goalsWebsite = payload.goals;
      state.valuesWebsite = payload.values;
      state.websiteEmployeeList = payload.employees;
      console.log(payload.employees);
    },
    Get_Activities_For_Website(state, payload) {
      state.websiteActivitiesList = payload;
    },
    Get_Contact_Info_For_Website(state, payload) {
      Object.assign(state.companyWebsiteDto, payload[0]);
      Object.assign(state.agentWebsiteDto, payload[1]);
    },
    Get_Service_For_Website(state, payload) {
      state.services = payload.services;
      state.stagesTitle = payload.stagesTitle;
      state.stages = payload.stages;
      state.videoUrl = payload.videoUrl;
    },
    Get_Products_For_Website(state, payload) {
      state.products = payload.products;
      state.filterdProducts = payload.products;
      store.dispatch("filterProductAction");
      Object.assign(state.productsInfo, payload.productsInfo);
      state.websiteMainCategoriesList = [
        { id: null, name: "الكل" },
        ...payload.mainCategoriesList,
      ];
      state.websiteSubCategoriesList = [
        { id: null, name: "الكل" },
        ...payload.subCategoriesList,
      ];
    },
    Reset_Inbox_Dto(state) {
      Object.assign(state.setContactDto, {
        id: 0,
        name: "",
        email: "",
        title: "",
        description: "",
        response: "",
      });
    },
    Get_Products_By_Id_For_Website(state, payload) {
      Object.assign(state.productDetailsForWebsite, payload.productDetails);
      state.productsListOther = payload.productsList;
    },
    Update_Data_Pagination_For_Products(state, currentPage) {
      store
        .dispatch("app/pageChanged", {
            currentPage: currentPage,
            pageLength: state.DataPageLengthProductWeb,
            items: state.filterdProducts
        })
        .then(data => {
            state.activeProductWeb = data
            state.DataFirstPageProductWeb = currentPage
        })
    },
    async filter_product_action(state) {
      state.filterdProducts = await state.products.filter((product) => {
        if (
          (!state.filterDto.main ||
            product.mainCategoryId == state.filterDto.main) &&

          (!state.filterDto.sup ||
            product.subCategoryId == state.filterDto.sup) &&

          (!state.filterDto.search.trim() || product.name.toLowerCase()
              .indexOf(state.filterDto.search.trim().toLowerCase()) != -1)
        )
          return true;
        return false;
      });
      store.dispatch('updateDataPaginationForProducts', 1)
    },
  }, 
  actions: {
    async getHomePageForWebsite({ commit }, lang) {
      const { data } = await axiosIns.get(
        "Website/GetHomePage?language=" +
          (!lang ? (store.state.appConfig.layout.isRTL ? 1 : 2) : lang)
      );
      commit("Get_Home_Page_For_Website", data);
    },
    async getAboutPageForWebsite({ commit }, lang) {
      const { data } = await axiosIns.get(
        "Website/GetAboutUs?language=" +
          (!lang ? (store.state.appConfig.layout.isRTL ? 1 : 2) : lang)
      );
      commit("Get_About_Page_For_Website", data);
    },
    async getActivitiesForWebsite({ commit }) {
      const { data } = await axiosIns.get(
        "Website/GetActivities?language=" +
          (store.state.appConfig.layout.isRTL ? 1 : 2)
      );
      commit("Get_Activities_For_Website", data);
    },
    async getContactInfoForWebsite({ commit }) {
      const { data } = await axiosIns.get("Website/GetContactInfo");
      commit("Get_Contact_Info_For_Website", data);
    },
    async getServiceForWebsite({ commit }) {
      const { data } = await axiosIns.get(
        "Website/GetServices?language=" +
          (store.state.appConfig.layout.isRTL ? 1 : 2)
      );
      commit("Get_Service_For_Website", data);
    },
    async getProductsForWebsite({ commit }) {
      const { data } = await axiosIns.get(
        "Website/GetProducts?language=" +
          (store.state.appConfig.layout.isRTL ? 1 : 2)
      );
      commit("Get_Products_For_Website", data);
    },
    async getProductsByIdForWebsite({ commit }, id) {
      const { data } = await axiosIns.get(
        `Website/GetProductDetails?productId=${id}&language=${(store.state.appConfig.layout.isRTL ? 1 : 2)}`
      );
      commit("Get_Products_By_Id_For_Website", data);
    },
    async setContact({ commit }, payload) {
      const bToast = new BToast();
      axiosIns
        .post("Contact/SetContact", payload)
        .then(() => {
          bToast.$bvToast.toast(`نجح الإرسال`, {
            title: "تم إرسال البريد بنجاح",
            variant: "success",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
          commit("Reset_Inbox_Dto");
        })
        .catch(() => {
          bToast.$bvToast.toast(`الرجاء إعادة المحاولة مجددا"`, {
            variant: "danger",
            title: "فشل إرسال البريد",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        });
    },
    updateDataPaginationForProducts(context, currentPage) {
      context.commit("Update_Data_Pagination_For_Products", currentPage)
    },
    filterProductAction({ commit }) {
      commit("filter_product_action");
    },
  },
};
