import axiosIns from "@/libs/axios";
import store from "@/store";
import { BToast } from "bootstrap-vue";
import Swal from "sweetalert2";

export default {
  state: {
    isCreateServiceSettingieModal: false,
    activeStagesList: [],
    servicesPageDto: {
      id: 1,
      pageType: 2,
      title: "",
      description: "",
      stages: [],
    },
    stageDto: {
      id: 0,
      description: "",
      order: 0,
      imageDto: {
        id: 0,
        path: "",
        base64: "",
      },
      pageSettingsId: 0,
      language: 1,
    },
    stagesFirstPage: 1,
    stagesPageLength: 6,
  },
  getters: {
    isCreateServiceSettingieModal({ isCreateServiceSettingieModal }) {
      return isCreateServiceSettingieModal;
    },
    servicesPageDto({ servicesPageDto }) {
      return servicesPageDto;
    },
    stageDto({ stageDto }) {
      return stageDto;
    },
    stagesFirstPage({ stagesFirstPage }) {
      return stagesFirstPage;
    },
    stagesPageLength({ stagesPageLength }) {
      return stagesPageLength;
    },
    activeStagesList({ activeStagesList }) {
      return activeStagesList;
    },
  },
  mutations: {
    Update_Stages_Pagination(state, currentPage) {
      store
        .dispatch("app/pageChanged", {
          currentPage: currentPage,
          pageLength: state.stagesPageLength,
          items: state.servicesPageDto.stages,
        })
        .then((data) => {
          state.activeStagesList = data;
          state.stagesFirstPage = currentPage;
        });
    },
    Get_Service_Setting_Page(state, data) {
      console.log(data);
      Object.assign(state.servicesPageDto, data);
      store.dispatch("updateStagesPagination", 1);
    },
    SET_SERVICE_SETTINGS(state, data)
    {
      Object.assign(state.servicesPageDto, data);
    },
    Get_Stage_Details_Dto(state, id) {
      Object.assign(
        state.stageDto,
        state.servicesPageDto.stages.find((item) => item.id == id)
      );
    },
    Reset_Stage_Dto(state) {
      Object.assign(state.stageDto, {
        id: 0,
        description: "",
        order: 0,
        imageDto: {
          id: 0,
          path: "",
          base64: "",
        },
        pageSettingsId: 1,
        language: 1,
      });
    },
    Create_Stage(state, payload) {
      state.servicesPageDto.stages.unshift(payload);
      store.dispatch("updateStagesPagination", 1);
    },
    Update_Stage(state, payload) {
      Object.assign(
        state.servicesPageDto.stages.find((item) => item.id == payload.id),
        payload
      );
      store.dispatch("updateStagesPagination", state.stagesFirstPage);
    },
    Delete_Stage(state, id) {
      state.servicesPageDto.stages.splice(
        state.servicesPageDto.stages.findIndex((item) => item.id == id),
        1
      );
      store.dispatch("updateStagesPagination", state.stagesFirstPage);
    },
  },
  actions: {
    updateStagesPagination(context, currentPage) {
      context.commit("Update_Stages_Pagination", currentPage);
    },
    setIsCreateServiceSettingieModal({ commit, state }, payload) {
      state.isCreateServiceSettingieModal = payload.is;
      if (!payload.id) {
        commit("Reset_Stage_Dto");
      } else {
        commit("Get_Stage_Details_Dto", payload.id);
      }
      return payload.is;
    },
    async getServiceSettingPage({ commit }, lang) {
      const { data } = await axiosIns.get(
        `PageSettings/GetPageSettings?language=${lang}&pageType=2`
      );
      commit("Get_Service_Setting_Page", data);
    },
    async setServiceSettings({ commit }, payload) {
      const bToast = new BToast();
      await axiosIns.post(
        `PageSettings/SetPageSettings`, payload
      ).then(({ data }) => {
        bToast.$bvToast.toast(`تم تعديل العنوان بنجاح`, {
          title: "تم التعديل",
          variant: "success",
          toaster: "b-toaster-bottom-left",
          solid: true,
          autoHideDelay: 2500,
          appendToast: true,
        });
        commit("SET_SERVICE_SETTINGS", data);
      });

    },
    actionStage({ state, commit }, dto) {
      const bToast = new BToast();
      dto.order = +dto.order
      if (!dto.id) {
        dto.pageSettingsId = state.servicesPageDto.id;
        axiosIns
          .post(`PageSettings/AddMultiStages`, dto)
          .then(({ data }) => {
            store.dispatch("setIsCreateServiceSettingieModal", {
              is: false,
              id: 0,
            });
            commit("Create_Stage", data);
            bToast.$bvToast.toast(`تم إضافة المرحلة`, {
              title: "تم الإضافة",
              variant: "success",
              toaster: "b-toaster-bottom-left",
              solid: true,
              autoHideDelay: 2000,
              appendToast: true,
            });
          })
          .catch(() => {
            bToast.$bvToast.toast("فشل إضافة المرحلة", {
              title: "فشل الإضافة",
              variant: "danger",
              toaster: "b-toaster-bottom-left",
              solid: true,
              autoHideDelay: 2000,
              appendToast: true,
            });
          });
      } else {
        axiosIns
          .post(`PageSettings/UpdateStage`, dto)
          .then(({ data }) => {
            commit("Update_Stage", data);
            bToast.$bvToast.toast(`تم تعديل المرحلة`, {
              title: "تم التعديل",
              variant: "success",
              toaster: "b-toaster-bottom-left",
              solid: true,
              autoHideDelay: 2000,
              appendToast: true,
            });
          })
          .catch(() => {
            bToast.$bvToast.toast("فشل تعديل المرحلة", {
              title: "فشل التعديل",
              variant: "danger",
              toaster: "b-toaster-bottom-left",
              solid: true,
              autoHideDelay: 2000,
              appendToast: true,
            });
          });
      }
    },

    deleteStage({ commit }, id) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: true,
      });
      swalWithBootstrapButtons
        .fire({
          title: "هل أنت متأكد؟",
          text: "هل فعلاً تريد حذف المرحلة ؟",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "نعم!",
          cancelButtonText: "إلغاء",
          reverseButtons: false,
        })
        .then((result) => {
          if (result.value) {
            const bToast = new BToast();
            axiosIns
              .delete(`PageSettings/DeleteStage?stageId=${id}`)
              .then((data) => {
                if (data) {
                  store.dispatch("setIsCreateServiceSettingieModal", {
                    is: false,
                    id: 0,
                  });
                  commit("Delete_Stage", id);
                  bToast.$bvToast.toast(`تم حذف المرحلة`, {
                    title: "تم الحذف",
                    variant: "success",
                    toaster: "b-toaster-bottom-left",
                    solid: true,
                    autoHideDelay: 2000,
                    appendToast: true,
                  });
                } else {
                  bToast.$bvToast.toast("فشل حذف المرحلة", {
                    title: "فشل الحذف",
                    variant: "danger",
                    toaster: "b-toaster-bottom-left",
                    solid: true,
                    autoHideDelay: 2000,
                    appendToast: true,
                  });
                }
              })
              .catch(() => {
                bToast.$bvToast.toast("فشل حذف المرحلة", {
                  title: "فشل الحذف",
                  variant: "danger",
                  toaster: "b-toaster-bottom-left",
                  solid: true,
                  autoHideDelay: 2000,
                  appendToast: true,
                });
              });
          }
        });
    },
  },
};
