import Vue from 'vue'
import bootstrapVue from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import AOS from "aos";
import "aos/dist/aos.css";

import i18n from '@/libs/i18n';
import router from './router'
import store from './store'
import App from './App.vue'
import VueMeta from 'vue-meta'
 
// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(bootstrapVue)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

// text truncate
var filter = function(text, length, clamp){
  clamp = clamp || '...';
  var node = document.createElement('div');
  node.innerHTML = text;
  var content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
};

Vue.filter('truncate', filter);
  
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

var formatArDate = function (date) {
  return new Date(date).toLocaleDateString("ar-SY", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
  });
};
Vue.filter('formatArDate', formatArDate);

Vue.config.productionTip = false

new Vue({
  created() {
    AOS.init('body');
  },
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
