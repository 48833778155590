import {All} from "../index"
export default [
  {
    path: "",
    name: "website",
    component: () => import('@/views/website/pages/main'),
    meta: () => ({
      roles: [All],
      pageTitle: 'الرئيسية',
      layout: 'full'
    })
  },
  {
    path: "about",
    name: "website about",
    component: () => import('@/views/website/pages/about'),
    meta: () => ({
      roles: [All],
      pageTitle: 'من نحن',
      layout: 'full'
    })
  },
  {
    path: "services",
    name: "website services",
    component: () => import('@/views/website/pages/services'),
    meta: () => ({
      roles: [All],
      pageTitle: 'خدماتنا',
      layout: 'full'
    })
  },
  {
    path: "products",
    name: "website products",
    component: () => import('@/views/website/pages/products'),
    meta: () => ({
      roles: [All],
      pageTitle: 'منتجاتنا',
      layout: 'full'
    })
  },
  {
    path: "products/:id",
    name: "website products details",
    props: true,
    component: () => import('@/views/website/pages/products/product-details.vue'),
    meta: () => ({
      roles: [All],
      pageTitle: 'تفاصيل المنتج',
      layout: 'full'
    })
  },
  {
    path: "activities/:id?",
    name: "website activities",
    component: () => import('@/views/website/pages/activities'),
    props: true,
    meta: () => ({
      roles: [All],
      pageTitle: 'نشاطاتنا',
      layout: 'full'
    })
  },
  {
    path: "contact",
    name: "website contact",
    component: () => import('@/views/website/pages/contact'),
    meta: () => ({
      roles: [All],
      pageTitle: 'اتصل بنا',
      layout: 'full'
    })
  }
]