import axiosIns from "@/libs/axios";
import router from "@/router";
import store from "@/store";
import { BToast } from "bootstrap-vue";
import Swal from "sweetalert2";

export default {
  state: {
    isCreateEmployeeModal: false,
    employeeFilter: {
      search: "",
    },
    EmployeeList: [],
    FilterdEmployeeList: [],
    ActiveEmployeeList: [],
    EmployeeFirstPage: 1,
    EmployeePageLength: 10,
    employeeDto: {
      id: 0,
      firstName: "",
      lastName: "",
      email: "",
      position: "",
      mobileNumber: "",
      dob: new Date(1970),
      imageDto: {
        id: 0,
        path: "",
        base64: "",
      },
    },
  },
  getters: {
    isCreateEmployeeModal({ isCreateEmployeeModal }) {
      return isCreateEmployeeModal;
    },
    EmployeeList({ EmployeeList }) {
      return EmployeeList;
    },
    employeeDto({ employeeDto }) {
      return employeeDto;
    },
    FilterdEmployeeList({ FilterdEmployeeList }) {
      return FilterdEmployeeList;
    },
    ActiveEmployeeList({ ActiveEmployeeList }) {
      return ActiveEmployeeList;
    },
    EmployeeFirstPage({ EmployeeFirstPage }) {
      return EmployeeFirstPage;
    },
    EmployeePageLength({ EmployeePageLength }) {
      return EmployeePageLength;
    },
    employeeFilter({ employeeFilter }) {
      return employeeFilter;
    },
  },
  mutations: {
    Update_Employee_Pagination(state, currentPage) {
      store
        .dispatch("app/pageChanged", {
          currentPage: currentPage,
          pageLength: state.EmployeePageLength,
          items: state.FilterdEmployeeList,
        })
        .then((data) => {
          state.ActiveEmployeeList = data;
          state.EmployeeFirstPage = currentPage;
        });
    },
    Get_employee_list(state, payload) {
      state.EmployeeList = payload;
      state.FilterdEmployeeList = payload;
      store.dispatch("filterEmployees", 1);
    },
    Create_employee(state, payload) {
      state.EmployeeList.unshift(payload);
      store.dispatch("filterEmployees", 1);
    },
    Get_Employee_By_Id(state, id) {
      Object.assign(
        state.employeeDto,
        state.EmployeeList.find((item) => item.id == id)
      );
      store.dispatch("filterEmployees", state.EmployeeFirstPage);
    },
    REMOVE_MULTI_EMPLOYEES(state, payload) {
      let MapOfIds = new Map();
      var idx;
      var tempList = [];
      for (idx = 0; idx < payload.length; idx++) {
        MapOfIds.set(payload[idx], 1);
      }
      for (idx = 0; idx < state.EmployeeList.length; idx++) {
        if (MapOfIds.has(state.EmployeeList[idx].id) === false) {
          tempList.push(state.EmployeeList[idx]);
        }
      }
      state.EmployeeList = tempList;

      store.dispatch("filterEmployees", 1);
      Swal.fire({
        title: "تم الحذف!",
        text: `تم حذف الموظفين بنجاح .`,
        icon: "success",
        showConfirmButton: false,
        timer: 2000
      });
    }
  },
  actions: {
    updateEmployeePagination(context, currentPage) {
      context.commit("Update_Employee_Pagination", currentPage);
    },
    setIsCreateEmployeeModal({ state }, is) {
      state.isCreateEmployeeModal = is;
      return is;
    },
    async getEmployeeList({ commit }) {
      const { data } = await axiosIns.get("Employee/GetEmployees");
      commit("Get_employee_list", data);
    },
    async getEmployeeById({ state, commit }, id) {
      if (!state.EmployeeList.length) {
        await store.dispatch("getEmployeeList");
      }
      commit("Get_Employee_By_Id", id);
    },
    createEmployee({ commit }, payload) {
      const bToast = new BToast();
      axiosIns
        .post("Employee/AddEmployee", payload)
        .then(({ data }) => {
          commit("Create_employee", data);
          bToast.$bvToast.toast(`تم إضافة الموظف`, {
            title: "تم الإضافة",
            variant: "success",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        })
        .catch(() => {
          bToast.$bvToast.toast("فشل إضافة الموظف", {
            title: "فشل الإضافة",
            variant: "danger",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        });
    },
    updateEmployee(ctx, payload) {
      const bToast = new BToast();
      axiosIns
        .post("Employee/UpdateEmployee", payload)
        .then(() => {
          bToast.$bvToast.toast(`تم تعديل الموظف`, {
            title: "تم التعديل",
            variant: "success",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        })
        .catch(() => {
          bToast.$bvToast.toast("فشل تعديل الموظف", {
            title: "فشل التعديل",
            variant: "danger",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        });
    },
    deleteEmployee(ctx, id) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: true,
      });
      swalWithBootstrapButtons
        .fire({
          title: "هل أنت متأكد؟",
          text: "هل فعلاً تريد حذف الموظف ؟",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "نعم!",
          cancelButtonText: "إلغاء",
          reverseButtons: false,
        })
        .then((result) => {
          if (result.value) {
            const bToast = new BToast();
            axiosIns
              .delete("Employee/DeleteEmployee?employeeId=" + id)
              .then(() => {
                router.push("../");
                bToast.$bvToast.toast(`تم حذف الموظف`, {
                  title: "تم الحذف",
                  variant: "success",
                  toaster: "b-toaster-bottom-left",
                  solid: true,
                  autoHideDelay: 2000,
                  appendToast: true,
                });
              })
              .catch(() => {
                bToast.$bvToast.toast("فشل حذف الموظف", {
                  title: "فشل الحذف",
                  variant: "danger",
                  toaster: "b-toaster-bottom-left",
                  solid: true,
                  autoHideDelay: 2000,
                  appendToast: true,
                });
              });
          }
        });
    },
    async deleteMultiEmployees({commit}, payload)
    {
      await axiosIns.delete('Employee/DeleteMultiEmployees',{data: payload});
      commit("REMOVE_MULTI_EMPLOYEES", payload)
    },
    async filterEmployees({ state }, page) {
      state.FilterdEmployeeList = state.EmployeeList.filter((item) => {
        return (
          (item["firstName"] + item["lastName"]).indexOf(
            state.employeeFilter.search
          ) != -1
        );
      });
      store.dispatch("updateEmployeePagination", page);
    },
  },
};
