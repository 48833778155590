import axiosIns from "@/libs/axios";
import store from "@/store";
import { BToast } from "bootstrap-vue";
import Swal from "sweetalert2";

export default {
  state: {
    isCreateCategorieModal: false,
    show: false,
    categoryFilter: {
      search: ''
    },
    subCategoryFilter: {
      search: ''
    },
    
    categoriesList: [],
    activeCategoriesList: [],
    filterdDataListCategories: [],
    DataFirstPageCategories: 1,
    DataPageLengthCategories: 10,
    
    subCategoriesList: [],
    activeSubCategoriesList: [],
    filterdDataListSubCategories: [],
    DataFirstPageSubCategories: 1,
    DataPageLengthSubCategories: 10,
    
    categoryDto: {
      id: 0,
      name: "",
      isMain: true,
      inHome: false,
      subCategoriesCount: 0,
      imageDto: {
        id: 0,
        path: null,
        base64: ""
      },
      mainCategoriesIds: [],
    },
    activeCategoryId: 0
  },
  getters: {
    categoryFilter({categoryFilter}) {
      return categoryFilter
    },
    subCategoryFilter({subCategoryFilter}) {
      return subCategoryFilter
    },
    filterdDataListCategories({filterdDataListCategories}){
      return filterdDataListCategories
    },
    activeCategoriesList({activeCategoriesList}) {
      return activeCategoriesList
    },
    DataFirstPageCategories({DataFirstPageCategories}){
      return DataFirstPageCategories
    },
    DataPageLengthCategories({DataPageLengthCategories}){
      return DataPageLengthCategories
    },
    
    filterdDataListSubCategories({filterdDataListSubCategories}) {
      return filterdDataListSubCategories
    },
    activeSubCategoriesList({activeSubCategoriesList}) {
      return activeSubCategoriesList
    },
    DataFirstPageSubCategories({DataFirstPageSubCategories}) {
      return DataFirstPageSubCategories
    },
    DataPageLengthSubCategories({DataPageLengthSubCategories}) {
      return DataPageLengthSubCategories
    },

    isCreateCategorieModal({ isCreateCategorieModal }) {
      return isCreateCategorieModal;
    },
    categoriesList({ categoriesList }) {
      return categoriesList;
    },
    subCategoriesList({ subCategoriesList }) {
      return subCategoriesList;
    },
    show({ show }) {
      return show;
    },
    categoryDto({ categoryDto }) {
      return categoryDto;
    },
    activeCategoryId({ activeCategoryId }) {
      return activeCategoryId;
    }
  },
  mutations: {
    Get_Categories(state, payload) {
      state.categoriesList = payload;
      state.filterdDataListCategories = payload
      store.dispatch("filterDataCategory", 1);
    },
    Get_Sub_Categories(state, payload) {
      state.activeCategoryId = payload.id;
      state.subCategoriesList = payload.data;
      state.filterdDataListSubCategories = payload.data;
      store.dispatch("filterDataSubCategory", 1);
    },
    set_new_categories(state, payload) {
      state.categoriesList.unshift(payload);
      store.dispatch("filterDataCategory", 1);
    },
    set_new_Multi_categories(state, payload) {
      state.subCategoriesList.unshift(payload);
      store.dispatch("filterDataSubCategory", 1);
    },
    Delete_Categories(state, pload) {
      if (pload.isMain) {
        state.categoriesList.splice(state.categoriesList.findIndex(item => item.id == pload.id), 1);
        store.dispatch("filterDataCategory", 1);
      } else {
        state.subCategoriesList.splice(state.subCategoriesList.findIndex(item => item.id == pload.id), 1);
        store.dispatch("filterDataSubCategory", 1);
      }
      store.dispatch("setIsCreateCategorieModal", false);
    },
    Reset_Categories_Dto(state) {
      Object.assign(state.categoryDto, {
        id: 0,
        name: "",
        isMain: true,
        inHome: false,
        subCategoriesCount: 0,
        imageDto: {
          id: 0,
          path: null,
          base64: ""
        },
        mainCategoriesIds: []
      });
    },
    get_Main_Category_Details(state, id) {
      Object.assign(
        state.categoryDto,
        state.categoriesList.find(item => item.id == id)
      );
    },
    get_Sub_Category_Details(state, id) {
      Object.assign(
        state.categoryDto,
        state.subCategoriesList.find(item => item.id == id)
      );
    },
    async update_Categories(state, payload){
      const propsId = payload.id;
      if(payload.isMain) {
        Object.assign(state.categoriesList.find(item => item.id == propsId), payload);
        state.show = true
        store.dispatch("filterDataCategory", state.DataFirstPageCategories);
      } else {
        let categories = state.subCategoriesList.find(item => item.id == propsId) 
        let newList = [...payload.mainCategoriesIds]
        for(let i = 0; i < newList.length; i++) {
          const id = newList[i]
          let categoriesIndex = await categories.mainCategoriesIds.indexOf(id)
          let payloadIndex = await payload.mainCategoriesIds.indexOf(id)
          if(categoriesIndex != -1 && payloadIndex != -1) {
            categories.mainCategoriesIds.splice(categoriesIndex, 1)
            payload.mainCategoriesIds.splice(payloadIndex, 1)
          }
        }
        await state.categoriesList.map(item => {
          if(categories.mainCategoriesIds.indexOf(item.id) != -1) {
            item.subCategoriesCount--
          } else if (payload.mainCategoriesIds.indexOf(item.id) != -1) {
            item.subCategoriesCount++
          }
        })
        Object.assign(categories, payload)
        categories.mainCategoriesIds = newList
        store.dispatch("filterDataSubCategory", state.DataFirstPageSubCategories);
      }
      store.dispatch('setIsCreateCategorieModal', false)
    },
    Update_Data_Pagination(state, currentPage) {
      store
        .dispatch("app/pageChanged", {
            currentPage: currentPage,
            pageLength: state.DataPageLengthCategories,
            items: state.filterdDataListCategories
        })
        .then(data => {
            state.activeCategoriesList = data
            state.DataFirstPageCategories = currentPage
        })
    }, 
    Update_Sub_Data_Pagination(state, currentPage) {
      store
        .dispatch("app/pageChanged", {
            currentPage: currentPage,
            pageLength: state.DataPageLengthSubCategories,
            items: state.filterdDataListSubCategories
        })
        .then(data => {
            state.activeSubCategoriesList = data
            state.DataFirstPageSubCategories = currentPage
        })
    },
  },
  actions: {
    setIsCreateCategorieModal({ state }, is) {
      state.isCreateCategorieModal = is;
      return is;
    },
    async getCategories({ commit }, lang) {
      const { data } = await axiosIns.get(
        "Category/GetMainCategories?language=" + lang
      );
      commit("Get_Categories", data);
    },
    async getSubCategories({ commit }, props) {
      const id = props.id;
      const language = props.language;
      const { data } = await axiosIns.get(
        `Category/GetSubCategories?mainCategoryId=${id}&language=${language}`
      );
      commit("Get_Sub_Categories", { data, id });
    },
    deleteCategories({ commit }, props) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: true,
      });
      swalWithBootstrapButtons
        .fire({
          title: "هل أنت متأكد؟",
          text: "هل فعلاً تريد حذف التصنيف ؟",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "نعم!",
          cancelButtonText: "إلغاء",
          reverseButtons: false,
        })
        .then((result) => {
          if (result.value) {
            const bToast = new BToast();
            axiosIns.delete(`Category/DeleteCategory?categoryId=` + props.id)
              .then(() => {
                commit("Delete_Categories", props);
                bToast.$bvToast.toast(`تم حذف التصنيف`, {
                  title: "تم الحذف",
                  variant: "success",
                  toaster: "b-toaster-bottom-left",
                  solid: true,
                  autoHideDelay: 2000,
                  appendToast: true,
                });
              }).catch((err) => {
                bToast.$bvToast.toast(err.response.data, {
                  title: "فشل الحذف",
                  variant: "danger",
                  toaster: "b-toaster-bottom-left",
                  solid: true,
                  autoHideDelay: 2000,
                  appendToast: true,
                });
              })
          }
        });
    },
    setNewCategories({ commit, state }, payload) {
      const bToast = new BToast();
      if (state.show == false) {
        payload.isMain = true;
        axiosIns.post(
          "Category/AddMultiCategories",
          payload
        ).then(({data}) => {
          commit("set_new_categories", data);
          bToast.$bvToast.toast(`تم الإضافة`, {
            title: "تم الإضافة بنجاح",
            variant: "success",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        }).catch((err) => {
          bToast.$bvToast.toast(err.response.data, {
            title: "فشل إضافة تصنيف",
            variant: "danger",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        })
      } else {
        payload.isMain = false;
        axiosIns.post(
          "Category/AddMultiCategories",
          payload
        ).then(({data}) => {
          commit("set_new_Multi_categories", data);
          bToast.$bvToast.toast(`تم الإضافة`, {
            title: "تم الإضافة بنجاح",
            variant: "success",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        }).catch((err) => {
          bToast.$bvToast.toast(err.response.data, {
            title: "فشل إضافة تصنيف",
            variant: "danger",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        })
      }
    },
    actionShow({ state }) {
      state.show = true;
    },
    actionNonShow({ state }) {
      state.show = false;
    },
    async updateCategories({ commit }, props) {     
      axiosIns.post("Category/UpdateMultiCategories", props).then((data) => {
        commit("update_Categories", data.data);
      }).catch(() => {
        
      })
    },
    resetCategoriesDto({ commit, state }, id) {
      if (!id) {
        commit("Reset_Categories_Dto");
      } else {
        if (state.show) {
          commit("get_Sub_Category_Details", id);
        } else if (!state.show) {
          commit("get_Main_Category_Details", id);
        }
      }
    },
    setOrUpdateCategories(ctx, props){
      if (!props.id) {     
          store.dispatch("setNewCategories", props)
      } else {  
          store.dispatch("updateCategories", props)
      }
    },
    updateDataPaginationCategories(context, currentPage) {
      context.commit("Update_Data_Pagination", currentPage)
    },
    updateSubDataPagination(context, currentPage) {
      context.commit("Update_Sub_Data_Pagination", currentPage)
    },
    async filterDataCategory({ state }, page) {
        state.filterdDataListCategories = await state.categoriesList.filter((item) => {
          return item.name.indexOf(state.categoryFilter.search) != -1
        });
        store.dispatch("updateDataPaginationCategories", page);
    },
    async filterDataSubCategory({ state }, page) {
        state.filterdDataListSubCategories = await state.subCategoriesList.filter((item) => {
          return item.name.indexOf(state.subCategoryFilter.search) != -1
        });
        store.dispatch("updateSubDataPagination", page);
    }
  }
};
