import axiosIns from "@/libs/axios"
import store from "@/store";
import Swal from "sweetalert2";
import { BToast } from "bootstrap-vue";
import { getUserData } from "@/auth/utils";
import useJwt from "@/auth/jwt/useJwt";
import globalEvent from "@/libs/global-event" 

export default {
  state: {
    isCreateAccountModal: false,
    activeUsersList: [],
    UsersFirstPage: 1,
    UsersPageLength: 10,
    users: [],
    rolesList: [],
    userForm: {
      id: 0,
      username: "",
      fullName: "",
      password: "",
      role: "",
    }
  },
  getters: {
    isCreateAccountModal({ isCreateAccountModal }) {
      return isCreateAccountModal
    },
    activeUsersList({ activeUsersList }) {
      return activeUsersList;
    },
    UsersFirstPage({ UsersFirstPage }) {
      return UsersFirstPage;
    },
    UsersPageLength({ UsersPageLength }) {
      return UsersPageLength;
    },
    users({ users }) {
      return users;
    },
    rolesList({ rolesList }) {
      return rolesList;
    },
    userForm({ userForm }) {
      return userForm;
    },
  },
  mutations: {
    UPDATE_USERS_PAGINATION(state, currentPage) {
      store
        .dispatch("app/pageChanged", {
          currentPage: currentPage,
          pageLength: state.UsersPageLength,
          items: state.users,
        })
        .then((data) => {
          state.activeUsersList = data;
          state.UsersFirstPage = currentPage;
        });
    },
    GET_USERS_ITEMS(state, payload) {
      state.users = payload.users;
      state.rolesList = payload.rolesList;
      store.dispatch("updateUsersPagination", 1);
    },
    GET_USER_DETAILS(state, payload) {
      Object.assign(state.userForm, payload.userDto);
      state.rolesList = payload.rolesList;
    },
    ADD_USER(state, payload) {
      state.users.unshift(payload);
      state.activeUsersList.unshift(payload);
      store.dispatch("updateUsersPagination", 1);
      const bToast = new BToast()
      bToast.$bvToast.toast(`تم إضافة المستخدم بنجاح`, {
        title: "تمت الإضافة",
        variant: "success",
        toaster: "b-toaster-bottom-left",
        solid: true,
        autoHideDelay: 2000,
        appendToast: true,
      });
    },
    UPDATE_USER(state, payload) {
      Object.assign(state.userForm, payload);
      if (payload.id == getUserData().id) {
        const userData = payload;
        useJwt.setToken(payload.token);
        localStorage.setItem(
            "userData",
            JSON.stringify(userData)
        );
        globalEvent.changeUserInfo()
      }
      store.dispatch("updateUsersPagination", 1);
      const bToast = new BToast()
      bToast.$bvToast.toast(`تم تعديل المستخدم بنجاح`, {
        title: "تم التعديل",
        variant: "success",
        toaster: "b-toaster-bottom-left",
        solid: true,
        autoHideDelay: 2000,
        appendToast: true,
      });
    },
    DELETE_USER(state, payload) {
      const index = state.activeUsersList.findIndex(item => item.id == payload);
      state.activeUsersList.splice(index, 1);

      Swal.fire({
        title: "تم الحذف!",
        text: `تم حذف المستخدم بنجاح .`,
        icon: "success",
        showConfirmButton: false,
        timer: 2000
      });

      store.dispatch("updateUsersPagination", 1);
    },
    ERROR_MESSAGE(state, payload) {
      Swal.fire({
        title: "يوجد خطأ !",
        text: `${payload}`,
        icon: "error",
        showConfirmButton: false,
        timer: 3000
      });
    },
  },
  actions: {
    setIsCreateAccountModal({ state }, is) {
      state.isCreateAccountModal = is
      return is
    },
    updateUsersPagination(context, currentPage) {
      context.commit("UPDATE_USERS_PAGINATION", currentPage);
    },
    async fetchUsers({ commit }) {
      const { data } = await axiosIns.get('Account/GetUsers');

      commit("GET_USERS_ITEMS", data);
    },
    async fetchUserDetails({ commit }, id) {
      const { data } = await axiosIns.get(`Account/GetUserDetails?userId=${id}`);

      commit("GET_USER_DETAILS", data);
    },
    async addUser({ commit }, payload) {
      await axiosIns
        .post('Account/AddUser', payload)
        .then((data) => {
          commit("ADD_USER", data.data);
        }).catch((error) => {
          commit("ERROR_MESSAGE", error.response.data);
        });
    },
    async updateUser({ commit }, payload) {
      await axiosIns
        .post('Account/UpdateUser', payload)
        .then((data) => {
          commit("UPDATE_USER", data.data);
        }).catch((error) => {
          commit("ERROR_MESSAGE", error.response.data);
        });
    },
    async deleteUser({ commit }, id) {
      await axiosIns.delete(`Account/DeleteUser?userId=${id}`);

      commit("DELETE_USER", id);
    }
  }
}