import axiosIns from "@/libs/axios";
import store from "@/store";
import router from "@/router";
import { BToast } from "bootstrap-vue";
import Swal from "sweetalert2";

export default {
  state: {
    isCreateServiceModal: false,
    isCreateProductModal: false,
    isCreateActiviteModal: false,
    isCreateCustomerModal: false,

    dataList: [],
    filterdDataList: [],
    ActiveDataList: [],
    DataFirstPage: 1,
    DataPageLength: 12,
    mainDataFilter: {
      search: "",
    },
    dolorPrice: 0,

    activitesDetails: {
      date: "",
      id: "",
      title: "",
      description: "",
      imageDto: {
        id: 0,
        path: "",
        base64: "",
      },
    },
    productDetails: {
      id: 0,
      name: "",
      description: "",
      sypPrice: 0,
      usdPrice: 0,
      isHidden: false,
      certified: true,
      imageDto: {
        id: 0,
        path: "",
        base64: "",
      },
      linkId: 0,
      language: 0,
      categoryBreakId: null,
      mainCategory: "",
      subCategory: "",
    },
    customerDetails: {
      id: 0,
      name: "",
      logoDto: {
        id: 0,
        path: "",
        base64: "",
      },
    },
    servicesDetails: {
      id: 0,
      name: "",
      description: "",
      order: 0,
      language: 1,
      imageDto: {
        id: 0,
        path: "",
        base64: "",
      },
    },
    productCategoriesList: [],
    currenciesList: [],
  },
  getters: {
    isCreateServiceModal({ isCreateServiceModal }) {
      return isCreateServiceModal;
    },
    isCreateProductModal({ isCreateProductModal }) {
      return isCreateProductModal;
    },
    isCreateActiviteModal({ isCreateActiviteModal }) {
      return isCreateActiviteModal;
    },
    isCreateCustomerModal({ isCreateCustomerModal }) {
      return isCreateCustomerModal;
    },
    dataList({ dataList }) {
      return dataList;
    },
    filterdDataList({ filterdDataList }) {
      return filterdDataList;
    },
    ActiveDataList({ ActiveDataList }) {
      return ActiveDataList;
    },
    DataFirstPage({ DataFirstPage }) {
      return DataFirstPage;
    },
    DataPageLength({ DataPageLength }) {
      return DataPageLength;
    },
    activitesDetails({ activitesDetails }) {
      return activitesDetails;
    },
    servicesDetails({ servicesDetails }) {
      return servicesDetails;
    },
    customerDetails({ customerDetails }) {
      return customerDetails;
    },
    mainDataFilter({ mainDataFilter }) {
      return mainDataFilter;
    },
    productDetails({ productDetails }) {
      return productDetails;
    },
    productCategoriesList({ productCategoriesList }) {
      return productCategoriesList;
    },
    dolorPrice({ dolorPrice }) {
      return dolorPrice;
    },
  },
  mutations: {
    // global
    Update_Data_Pagination(state, currentPage) {
      store
        .dispatch("app/pageChanged", {
          currentPage: currentPage,
          pageLength: state.DataPageLength,
          items: state.filterdDataList,
        })
        .then((data) => {
          state.ActiveDataList = data;
          state.DataFirstPage = currentPage;
        });
    },
    Get_Data(state, data) {
      state.dataList = data;
      state.filterdDataList = data;
      store.dispatch("filterdData", 1);
    },
    Get_Product_Data(state, data) {
      state.dataList = data.products;
      state.filterdDataList = data.products;
      store.dispatch("filterdData", 1);
      state.currenciesList = data.currenciesList;
      state.productCategoriesList = data.categoriesList;
      state.dolorPrice = data.dollarPrice;
    },
    set_new_main_item(state, payload) {
      state.dataList.unshift(payload);
      store.dispatch("filterdData", 1);
    },
    async update_main_data(state, pload) {
      const index = await state.dataList.findIndex(
        (item) => item.id == pload.id
      );
      Object.assign(state.dataList[index], pload);
      store.dispatch("filterdData", state.DataFirstPage);
    },
    // get details
    Get_Activity_By_Id(state, id) {
      Object.assign(
        state.activitesDetails,
        state.dataList.find((item) => item.id == id)
      );
    },
    get_product_by_id(state, id) {
      Object.assign(
        state.productDetails,
        state.dataList.find((item) => item.id == id)
      );
    },
    get_services_by_id(state, id) {
      Object.assign(
        state.servicesDetails,
        state.dataList.find((item) => item.id == id)
      );
    },
    Get_Customer_By_Id(state, id) {
      Object.assign(
        state.customerDetails,
        state.dataList.find((item) => item.id == id)
      );
    },
  },
  actions: {
    // global actions
    updateDataPagination(context, currentPage) {
      context.commit("Update_Data_Pagination", currentPage);
    },
    setIsCreateServiceModal({ state }, is) {
      state.isCreateServiceModal = is;
      return is;
    },
    setIsCreateProductModal({ state }, is) {
      state.isCreateProductModal = is;
      return is;
    },
    setIsCreateActiviteModal({ state }, is) {
      state.isCreateActiviteModal = is;
      return is;
    },
    setIsCreateCustomerModal({ state }, is) {
      state.isCreateCustomerModal = is;
      return is;
    },

    // get actions
    async getData({ commit }, payload) {
      let data;
      if (payload.type == 0) {
        data = await axiosIns
          .get("Service/GetServices?language=" + payload.lang)
          .then((data) => data.data);
        commit("Get_Data", data);
      } else if (payload.type == 1) {
        data = await axiosIns
          .get("Product/GetProducts?language=" + payload.lang)
          .then((data) => data.data);
        commit("Get_Product_Data", data);
      } else if (payload.type == 2) {
        data = await axiosIns
          .get("Activity/GetActivities?language=" + payload.lang)
          .then((data) => data.data);
        commit("Get_Data", data);
      } else if (payload.type == 3) {
        data = await axiosIns
          .get("Customer/GetCustomers")
          .then((data) => data.data);
        commit("Get_Data", data);
      }
    },

    async getActivityById({ commit, state }, payload) {
      // payload : {type, id}
      if (!state.dataList.length) {
        await store.dispatch("getData", payload.type);
      }
      commit("Get_Activity_By_Id", payload.id);
    },
    async getServicesById({ commit, state }, payload) {
      // payload : {type, id}
      if (!state.dataList.length) {
        await store.dispatch("getData", payload.type);
      }
      commit("get_services_by_id", payload.id);
    },
    async getProductById({ commit, state }, payload) {
      if (!state.dataList.length) {
        await store.dispatch("getData", payload.type);
      }
      commit("get_product_by_id", payload.id);
    },
    async getCustomerById({ commit, state }, payload) {
      // payload : {type, id}
      if (!state.dataList.length) {
        await store.dispatch("getData", payload.type);
      }
      commit("Get_Customer_By_Id", payload.id);
    },

    // create actions
    setNewService({ commit }, payload) {
      const bToast = new BToast();
      axiosIns
        .post("Service/AddMultiServices", payload)
        .then(({ data }) => {
          commit("set_new_main_item", data);
          bToast.$bvToast.toast(`تم إضافة الخدمة`, {
            title: "تم الإضافة",
            variant: "success",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        })
        .catch(() => {
          bToast.$bvToast.toast("فشل إضافة الخدمة", {
            title: "فشل الإضافة",
            variant: "danger",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        });
    },
    setNewProduct({ commit }, payload) {
      const bToast = new BToast();
      axiosIns
        .post("Product/AddMultiProducts", payload)
        .then(({ data }) => {
          commit("set_new_main_item", data);
          bToast.$bvToast.toast(`تم إضافة المنتج`, {
            title: "تم الإضافة",
            variant: "success",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        })
        .catch(() => {
          bToast.$bvToast.toast("فشل إضافة المنتج", {
            title: "فشل الإضافة",
            variant: "danger",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        });
    },
    setNewActivities({ commit }, payload) {
      const bToast = new BToast();
      axiosIns
        .post("Activity/AddMultiActivities", payload)
        .then(({ data }) => {
          commit("set_new_main_item", data);
          bToast.$bvToast.toast(`تم إضافة النشاط`, {
            title: "تم الإضافة",
            variant: "success",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        })
        .catch(() => {
          bToast.$bvToast.toast("فشل إضافة النشاط", {
            title: "فشل الإضافة",
            variant: "danger",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        });
    },
    setNewCustomer({ commit }, payload) {
      const bToast = new BToast();
      axiosIns
        .post("Customer/AddCustomer", payload)
        .then(({ data }) => {
          commit("set_new_main_item", data);
          bToast.$bvToast.toast(`تم إضافة الزبون`, {
            title: "تم الإضافة",
            variant: "success",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        })
        .catch(() => {
          bToast.$bvToast.toast("فشل إضافة الزبون", {
            title: "فشل الإضافة",
            variant: "danger",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        });
    },

    // update actions
    updateCustomer({ commit }, payload) {
      const bToast = new BToast();
      axiosIns
        .post("Customer/UpdateCustomer", payload)
        .then(({ data }) => {
          commit("update_main_data", data);
          bToast.$bvToast.toast(`تم تعديل الزبون`, {
            title: "تم التعديل",
            variant: "success",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        })
        .catch(() => {
          bToast.$bvToast.toast("فشل تعديل الزبون", {
            title: "فشل التعديل",
            variant: "danger",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        });
    },
    updateProduct({ commit }, payload) {
      const bToast = new BToast();
      axiosIns
        .post("Product/UpdateProduct", payload)
        .then(({ data }) => {
          commit("update_main_data", data);
          bToast.$bvToast.toast(`تم تعديل المنتج`, {
            title: "تم التعديل",
            variant: "success",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        })
        .catch(() => {
          bToast.$bvToast.toast("فشل تعديل المنتج", {
            title: "فشل التعديل",
            variant: "danger",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        });
    },
    updateServices({ commit }, payload) {
      const bToast = new BToast();
      axiosIns
        .post("Service/UpdateService", payload)
        .then(({ data }) => {
          commit("update_main_data", data);
          bToast.$bvToast.toast(`تم تعديل الخدمة`, {
            title: "تم التعديل",
            variant: "success",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        })
        .catch(() => {
          bToast.$bvToast.toast("فشل تعديل الخدمة", {
            title: "فشل التعديل",
            variant: "danger",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        });
    },
    updateActivities({ commit }, payload) {
      const bToast = new BToast();
      axiosIns
        .post("Activity/UpdateActivity", payload)
        .then(({ data }) => {
          commit("update_main_data", data);
          bToast.$bvToast.toast(`تم تعديل النشاط`, {
            title: "تم التعديل",
            variant: "success",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        })
        .catch(() => {
          bToast.$bvToast.toast("فشل تعديل النشاط", {
            title: "فشل التعديل",
            variant: "danger",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        });
    },

    // delete actions
    deleteItemServices(ctx, payload) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: true,
      });
      swalWithBootstrapButtons
        .fire({
          title: "هل أنت متأكد؟",
          text: "هل فعلاً تريد حذف الخدمة ؟",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "نعم!",
          cancelButtonText: "إلغاء",
          reverseButtons: false,
        })
        .then((result) => {
          if (result.value) {
            const bToast = new BToast();
            axiosIns
              .delete("Service/deleteService?serviceId=" + payload)
              .then(() => {
                router.push("../");
                bToast.$bvToast.toast(`تم حذف الخدمة`, {
                  title: "تم الحذف",
                  variant: "success",
                  toaster: "b-toaster-bottom-left",
                  solid: true,
                  autoHideDelay: 2000,
                  appendToast: true,
                });
              })
              .catch(() => {
                bToast.$bvToast.toast("فشل حذف الخدمة", {
                  title: "فشل الحذف",
                  variant: "danger",
                  toaster: "b-toaster-bottom-left",
                  solid: true,
                  autoHideDelay: 2000,
                  appendToast: true,
                });
              });
          }
        });
    },
    deleteItemActivities(ctx, payload) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: true,
      });
      swalWithBootstrapButtons
        .fire({
          title: "هل أنت متأكد؟",
          text: "هل فعلاً تريد حذف النشاط ؟",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "نعم!",
          cancelButtonText: "إلغاء",
          reverseButtons: false,
        })
        .then((result) => {
          if (result.value) {
            const bToast = new BToast();
            axiosIns
              .delete("Activity/deleteActivity?activityId=" + payload)
              .then(() => {
                router.push("../");
                bToast.$bvToast.toast(`تم حذف النشاط`, {
                  title: "تم الحذف",
                  variant: "success",
                  toaster: "b-toaster-bottom-left",
                  solid: true,
                  autoHideDelay: 2000,
                  appendToast: true,
                });
              })
              .catch(() => {
                bToast.$bvToast.toast("فشل حذف النشاط", {
                  title: "فشل الحذف",
                  variant: "danger",
                  toaster: "b-toaster-bottom-left",
                  solid: true,
                  autoHideDelay: 2000,
                  appendToast: true,
                });
              });
          }
        });
    },
    deleteItemCustomer(ctx, payload) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: true,
      });
      swalWithBootstrapButtons
        .fire({
          title: "هل أنت متأكد؟",
          text: "هل فعلاً تريد حذف الزبون ؟",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "نعم!",
          cancelButtonText: "إلغاء",
          reverseButtons: false,
        })
        .then((result) => {
          if (result.value) {
            const bToast = new BToast();
            axiosIns
              .delete("Customer/deleteCustomer?CustomerId=" + payload)
              .then(() => {
                router.push("../");
                bToast.$bvToast.toast(`تم حذف الزبون`, {
                  title: "تم الحذف",
                  variant: "success",
                  toaster: "b-toaster-bottom-left",
                  solid: true,
                  autoHideDelay: 2000,
                  appendToast: true,
                });
              })
              .catch(() => {
                bToast.$bvToast.toast("فشل حذف الزبون", {
                  title: "فشل الحذف",
                  variant: "danger",
                  toaster: "b-toaster-bottom-left",
                  solid: true,
                  autoHideDelay: 2000,
                  appendToast: true,
                });
              });
          }
        });
    },
    deleteItemProduct(ctx, payload) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: true,
      });
      swalWithBootstrapButtons
        .fire({
          title: "هل أنت متأكد؟",
          text: "هل فعلاً تريد حذف المنتج ؟",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "نعم!",
          cancelButtonText: "إلغاء",
          reverseButtons: false,
        })
        .then((result) => {
          if (result.value) {
            const bToast = new BToast();
            axiosIns
              .delete("Product/DeleteProduct?productId=" + payload)
              .then(() => {
                router.push("../");
                bToast.$bvToast.toast(`تم حذف المنتج`, {
                  title: "تم الحذف",
                  variant: "success",
                  toaster: "b-toaster-bottom-left",
                  solid: true,
                  autoHideDelay: 2000,
                  appendToast: true,
                });
              })
              .catch(() => {
                bToast.$bvToast.toast("فشل حذف المنتج", {
                  title: "فشل الحذف",
                  variant: "danger",
                  toaster: "b-toaster-bottom-left",
                  solid: true,
                  autoHideDelay: 2000,
                  appendToast: true,
                });
              });
          }
        });
    },

    async filterdData({ state }, page) {
      state.filterdDataList = await state.dataList.filter((item) => {
        return (
          (item["name"] &&
            item["name"].indexOf(state.mainDataFilter.search) != -1) ||
          (item["title"] &&
            item["title"].indexOf(state.mainDataFilter.search) != -1)
        );
      });
      store.dispatch("updateDataPagination", page);
    },
  },
};
