import {Admin, Support, Editor} from "../index"
export default [
  {
    path: "/admin/main",
    components: {
      default: () => import("@/views/main-sections/index.vue"),
      "bread-actions": () => import("@/views/main-sections/button-index.vue"),
    },
    children: [
      {
        path: "",
        name: "main",
        redirect: "/main/services",
      },
      {
        path: "services/:lang?",
        name: "services",
        components: {
          default: () =>
            import(
              "@/views/main-sections/pages/services-products-activites-customers/index.vue"
            ),
          actions: () =>
            import(
              "@/views/main-sections/pages/services-products-activites-customers/services/components/button-create-service.vue"
            ),
        },
        props: {
          default: true,
          actions: true,
        },
        meta: () => ({
          pageTitle: "الخدمات",
          roles: [Admin, Editor],
          breadcrumb: [
            {
              text: "الخدمات",
              icon: "briefcase-alt",
              active: true,
            },
          ],
        }),
      },
      {
        path: "services/details/:id/:lang?",
        name: "service details",
        component: () =>
          import(
            "@/views/main-sections/pages/services-products-activites-customers/services/details.vue"
          ),
        props: true,
        meta: () => ({
          pageTitle: "الخدمات",
          roles: [Admin, Editor],
          breadcrumb: [
            {
              text: "الخدمات",
              to: "/admin/main/services",
              icon: "briefcase-alt",
              active: false,
            },
            {
              text: "تفاصيل الخدمة",
              active: true,
            },
          ],
        }),
      },
      {
        path: "products/:lang?",
        name: "products",
        components: {
          default: () =>
            import(
              "@/views/main-sections/pages/services-products-activites-customers/index.vue"
            ),
          actions: () =>
            import(
              "@/views/main-sections/pages/services-products-activites-customers/products/components/button-create-product.vue"
            ),
        },
        props: {
          default: true,
        },
        meta: () => ({
          pageTitle: "المنتجات",
          roles: [Admin, Editor],
          breadcrumb: [
            {
              text: "المنتجات",
              icon: "bookmark",
              active: true,
            },
          ],
        }),
      },
      {
        path: "products/details/:id/:lang?",
        name: "product details",
        component: () =>
          import(
            "@/views/main-sections/pages/services-products-activites-customers/products/details.vue"
          ),
        props: true,
        meta: () => ({
          pageTitle: "المنتجات",
          roles: [Admin, Editor],
          breadcrumb: [
            {
              text: "المنتجات",
              to: "/admin/main/products",
              icon: "bookmark",
              active: false,
            },
            {
              text: "تفاصيل المنتج",
              active: true,
            },
          ],
        }),
      },
      {
        path: "activites/:lang?",
        name: "activites",
        components: {
          default: () =>
            import(
              "@/views/main-sections/pages/services-products-activites-customers/index.vue"
            ),
          actions: () =>
            import(
              "@/views/main-sections/pages/services-products-activites-customers/activites/components/button-create-activite.vue"
            ),
        },
        props: {
          default: true,
        },
        meta: () => ({
          pageTitle: "النشاطات",
          roles: [Admin, Editor],
          breadcrumb: [
            {
              text: "النشاطات",
              icon: "server",
              active: true,
            },
          ],
        }),
      },
      {
        path: "activites/details/:id/:lang?",
        name: "activite details",
        component: () =>
          import(
            "@/views/main-sections/pages/services-products-activites-customers/activites/details.vue"
          ),
        props: true,
        meta: () => ({
          pageTitle: "النشاطات",
          roles: [Admin, Editor],
          breadcrumb: [
            {
              text: "النشاطات",
              to: "/admin/main/activites",
              icon: "server",
              active: false,
            },
            {
              text: "تفاصيل النشاط",
              active: true,
            },
          ],
        }),
      },
      {
        path: "employees",
        name: "employees",
        components: {
          default: () => import("@/views/main-sections/pages/employees"),
          actions: () =>
            import(
              "@/views/main-sections/pages/employees/components/button-create-employee.vue"
            ),
        },
        props: {
          default: true,
        },
        meta: () => ({
          pageTitle: "الموظفين",
          roles: [Admin],
          breadcrumb: [
            {
              text: "الموظفين",
              icon: "users-alt",
              active: true,
            },
          ],
        }),
      },
      {
        path: "employees/details/:id",
        name: "employees details",
        component: () =>
          import("@/views/main-sections/pages/employees/details.vue"),
        props: true,
        meta: () => ({
          pageTitle: "الموظفين",
          roles: [Admin],
          breadcrumb: [
            {
              text: "الموظفين",
              active: false,
              icon: "users-alt",
              to: "/admin/main/employees",
            },
            {
              text: "تفاصيل الموظف",
              active: true,
            },
          ],
        }),
      },
      {
        path: "inbox",
        name: "inbox",
        components: {
          default: () => import("@/views/main-sections/pages/inbox"),
          actions: () =>
            import(
              "@/views/main-sections/pages/inbox/components/button-create-inbox.vue"
            ),
        },
        meta: () => ({
          pageTitle: "بريد الشركة",
          roles: [Admin, Support],
          breadcrumb: [
            {
              text: "بريد الشركة",
              icon: "archive-alt",
              active: true,
            },
          ],
        }),
      },
      {
        path: "inbox/details/0",
        redirect: "/admin/main/inbox"
      },
      {
        path: "inbox/details/:id",
        name: "inbox details",
        component: () => import("@/views/main-sections/pages/inbox/details"),
        props: true,
        meta: () => ({
          pageTitle: "بريد الشركة",
          roles: [Admin, Support],
          breadcrumb: [
            {
              text: "بريد الشركة",
              icon: "archive-alt",
              to: "/admin/main/inbox",
              active: false,
            },
            {
              text: "تفاصيل البريد",
              active: true,
            },
          ],
        }),
      },
      {
        path: "customers",
        name: "customers",
        components: {
          default: () =>
            import(
              "@/views/main-sections/pages/services-products-activites-customers/index.vue"
            ),
          actions: () =>
            import(
              "@/views/main-sections/pages/services-products-activites-customers/customers/components/button-create-customer.vue"
            ),
        },
        meta: () => ({
          pageTitle: "الزبائن",
          roles: [Admin],
          breadcrumb: [
            {
              text: "الزبائن",
              icon: "user-circle",
              active: true,
            },
          ],
        }),
      },
      {
        path: "customers/details/:id/:lang?",
        name: "customer details",
        component: () =>
          import(
            "@/views/main-sections/pages/services-products-activites-customers/customers/details.vue"
          ),
        props: true,
        meta: () => ({
          pageTitle: "الزبائن",
          roles: [Admin],
          breadcrumb: [
            {
              text: "الزبائن",
              to: "/admin/main/customers",
              icon: "user-circle",
              active: false,
            },
            {
              text: "تفاصيل الزبون",
              active: true,
            },
          ],
        }),
      },
    ],
  },
];
