import axiosIns from "@/libs/axios";
import store from "@/store";
import router from "@/router";
import { BToast } from "bootstrap-vue";
import Swal from "sweetalert2";

export default {
  state: {
    inboxList: [],
    activeInboxList: [],
    filteredInboxList: [],
    inboxFirstPage: 1,
    inboxPageLength: 10,
    inboxFilter: {
      search: "",
    },
    inboxDto: {
      id: 0,
      name: "",
      email: "",
      title: "",
      description: "",
      response: "",
      responseDate: null
    },
  },
  getters: {
    activeInboxList({ activeInboxList }) {
      return activeInboxList;
    },
    filteredInboxList({ filteredInboxList }) {
      return filteredInboxList;
    },
    inboxList({ inboxList }) {
      return inboxList;
    },
    inboxFirstPage({ inboxFirstPage }) {
      return inboxFirstPage;
    },
    inboxPageLength({ inboxPageLength }) {
      return inboxPageLength;
    },
    inboxFilter({ inboxFilter }) {
      return inboxFilter;
    },
    inboxDto({ inboxDto }) {
      return inboxDto;
    },
  },
  mutations: {
    Update_Inbox_Pagination(state, currentPage) {
      store
        .dispatch("app/pageChanged", {
          currentPage: currentPage,
          pageLength: state.inboxPageLength,
          items: state.filteredInboxList,
        })
        .then((data) => {
          state.activeInboxList = data;
          state.inboxFirstPage = currentPage;
        });
    },
    Get_Inbox_By_Id(state, payload) {
      Object.assign(state.inboxDto, payload);
    },
    Get_Inbox(state, payload) {
      state.inboxList = payload;
      state.filteredInboxList = payload;
      store.dispatch("filterInbox", 1);
    },
    SET_REPLY(state, payload)
    {
      Object.assign(state.inboxDto, payload.data);
    },
    REMOVE_MULTI_INBOX(state, payload) {
      let MapOfIds = new Map();
      var idx;
      var tempList = [];
      for (idx = 0; idx < payload.length; idx++) {
        MapOfIds.set(payload[idx], 1);
      }
      for (idx = 0; idx < state.inboxList.length; idx++) {
        if (MapOfIds.has(state.inboxList[idx].id) === false) {
          tempList.push(state.inboxList[idx]);
        }
      }
      state.inboxList = tempList;

      store.dispatch("filterInbox", 1);
      Swal.fire({
        title: "تم الحذف!",
        text: `تم حذف البريد المحدد بنجاح .`,
        icon: "success",
        showConfirmButton: false,
        timer: 2000
      });
    }
  },
  actions: {
    updateInboxPagination(content, currentPage) {
      content.commit("Update_Inbox_Pagination", currentPage);
    },
    async getInbox({ commit }) {
      const { data } = await axiosIns.get("Contact/GetContacts");
      commit("Get_Inbox", data);
    },
    async getInboxById({ commit }, id) {
      const { data } = await axiosIns.get(
        "Contact/GetContactDetails?contactId=" + id
      );
      commit("Get_Inbox_By_Id", data);
    },


    setContactReply({commit}, payload) {
      const bToast = new BToast();
      axiosIns
        .post("Contact/SetResponse", payload)
        .then((data) => {
          console.log(data);
          bToast.$bvToast.toast(`تم إرسال الرد بنجاح`, {
            title: "نجح الإرسال",
            variant: "success",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
          commit("SET_REPLY", data);
        })
        .catch(() => {
          bToast.$bvToast.toast(`الرجاء إعادة المحاولة مجددا"`, {
            variant: "danger",
            title: "فشل إرسال الرد",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        });
    },
    deleteInbox(ctx, id) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: true,
      });
      swalWithBootstrapButtons
        .fire({
          title: "هل أنت متأكد؟",
          text: "هل فعلاً تريد حذف البريد ؟",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "نعم!",
          cancelButtonText: "إلغاء",
          reverseButtons: false,
        })
        .then((result) => {
          if (result.value) {
            const bToast = new BToast();
            axiosIns
              .delete("Contact/DeleteContact?contactId=" + id)
              .then(() => {
                router.push("/admin/main/inbox");
                bToast.$bvToast.toast(`تم حذف البريد`, {
                  title: "تم الحذف",
                  variant: "success",
                  toaster: "b-toaster-bottom-left",
                  solid: true,
                  autoHideDelay: 2000,
                  appendToast: true,
                });
              })
              .catch(() => {
                bToast.$bvToast.toast("فشل حذف البريد", {
                  title: "فشل الحذف",
                  variant: "danger",
                  toaster: "b-toaster-bottom-left",
                  solid: true,
                  autoHideDelay: 2000,
                  appendToast: true,
                });
              });
          }
        });
    },
    async deleteMultiInbox({commit}, payload)
    {
      await axiosIns.delete('Contact/DeleteMultiContacts',{data: payload});
      commit("REMOVE_MULTI_INBOX", payload)
    },
    async filterInbox({ state }, page) {
      state.filteredInboxList = await store.dispatch("app/filterItems", {
        items: "inboxList",
        key: "title",
        search: state.inboxFilter.search,
      });
      store.dispatch("updateInboxPagination", page);
    },
  },
};
